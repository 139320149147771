<!-- eslint-disable vue/no-v-html -->
<template>
	<page-section>
		<div class="row no-gutters h-100">
			<div class="col d-md-flex align-items-end h-100">
				<div class="row h-100">
					<div
						class="col-12 col-md-6 d-flex justify-content-end align-items-center"
						:class="{
							'h-100': !['xs', 'sm', 'md'].includes(windowWidth),
						}">
						<div class="d-block h-100">
							<img
								class="w-100 mw-100 mh-100"
								style="height: auto"
								:src="image">
						</div>
					</div>
					<div
						class="col-12 col-md-6"
						:class="{
							'h-100': !['xs', 'sm', 'md'].includes(windowWidth),
						}">
						<div class="row mb-3">
							<div class="col">
								<span
									:style="makeHighlightStyle('color')"
									class="section-header">
									{{ title }}
								</span>
							</div>
						</div>
						<div class="row">
							<div class="col">
								<div v-html="description" />
							</div>
						</div>
						<div class="row">
							<div class="col">
								<div
									:class="['xs', 'sm'].includes(windowWidth) ? 'w-100' : 'px-5'"
									class="btn btn-lg text-white pointer rounded-0 py-2"
									:style="makeHighlightStyle('background-color')"
									@click="scrollTo('buy-section')">
									<div
										:class="['xs', 'sm'].includes(windowWidth) ? '' : 'mx-5'"
										class="font-weight-bolder h6 my-2">
										{{ translate('buy_now') }}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</page-section>
</template>
<script>
import WindowSizes from '@/mixins/WindowSizes';
import { Store } from '@/translations';
import PageSection from '../PageSection';
import Color from '../../mixins/Color';
import Scroll from '../../mixins/Scroll';

export default {
	name: 'Overview',
	messages: [Store],
	components: {
		PageSection,
	},
	mixins: [Color, Scroll, WindowSizes],
	props: {
		highlightColor: {
			type: String,
			default: '',
		},
		image: {
			type: String,
			required: true,
		},
		title: {
			type: String,
			required: true,
		},
		description: {
			type: String,
			required: true,
		},
		ctaUrl: {
			type: String,
			default: '',
		},
		icons: {
			type: Array,
			required: true,
		},
	},
};
</script>
