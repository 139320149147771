<template>
	<div
		class="w-100 landing-page"
		:style="colorsStyles">
		<hero
			id="hero"
			:background="heroData.background"
			:logo="heroData.logo"
			:slogan="heroData.slogan"
			scroll-target="overview-section" />
		<lazy
			id="overview-section"
			:style="`height: ${overviewData.height}px`"
			:min-height="overviewData.height">
			<overview
				:highlight-color="highlightColor"
				:image="overviewData.image"
				:title="overviewData.title"
				:description="overviewData.description"
				:cta-url="overviewData.ctaUrl"
				:icons="overviewData.icons" />
		</lazy>
		<lazy
			:style="`height: ${iconsData.height}px`"
			:min-height="iconsData.height">
			<icons-section
				:icons="iconsData.icons"
				:max-cols="iconsData.cols" />
		</lazy>
		<lazy
			:style="`height: ${videoData.height}px`"
			:min-height="videoData.height">
			<video-section
				:highlight-color="highlightColor"
				:title="videoData.title"
				:url="videoData.url"
				:preview-img="videoData.previewImg" />
		</lazy>
		<lazy
			:style="`height: ${benefitsData.height}px`"
			:min-height="benefitsData.height">
			<benefits
				:highlight-color="highlightColor"
				:sections="benefitsData.sections" />
		</lazy>
		<lazy
			:style="`height: ${reviewsData.height}px`"
			:min-height="reviewsData.height">
			<reviews
				:highlight-color="highlightColor"
				:reviews="reviewsData.reviews"
				:video-url="reviewsData.videoUrl"
				:text-color="reviewsData.textColor" />
		</lazy>
		<lazy
			id="buy-section"
			min-height="300">
			<buy-section
				:highlight-color="highlightColor"
				:background="buySectionData.background"
				:title="buySectionData.title"
				:product="buySectionData.searchName" />
		</lazy>
		<lazy min-height="300">
			<contact-section
				:highlight-color="highlightColor"
				:loading="loadingReplicatedData"
				:email="email"
				:image="contactData.image"
				:title="contactData.title"
				:content="contactData.content"
				:cta="contactData.cta"
				:container-style="['xs', 'sm'].includes(windowWidth) ? '' : 'height: 400px !important;'"
				@mail="openMailer" />
		</lazy>
	</div>
</template>
<script>
import WindowSizes from '@/mixins/WindowSizes';
import { PRODUCT_PAGES } from '@/settings/LandingPages';
import { SPECIAL_VALUES } from '@/settings/Replicated';
import { S3_PATH } from '@/settings/Images';
import { LandingPages, Testimonials } from '@/translations';
import { applyAttrsToHtmlString } from '@/util/HtmlHelper';
import ReplicatedSite from '@/util/ReplicatedSite';
import Benefits from './Benefits';
import BuySection from '../BuySection';
import ContactSection from '../ContactSection';
import Hero from '../Hero';
import IconsSection from '../IconsSection';
import Overview from './Overview';
import Reviews from '../Reviews';
import VideoSection from '../VideoSection';
import { RESTRICTED_BY_COUNTRY } from '@/settings/LandingPages2';
import Lazy from '@/components/Lazy';
import { META_DESCRIPTION } from '@/settings/Meta';

export default {
	name: 'ProductLandingPage',
	metaInfo() {
		const content = META_DESCRIPTION[`landing_page_${this.pageName.toLowerCase()}`];
		if (content !== undefined) {
			return {
				meta: [
					{ vmid: 'description', name: 'description', content },
				],
			};
		}
		return {};
	},
	messages: [LandingPages, Testimonials],
	components: {
		Benefits,
		BuySection,
		Hero,
		IconsSection,
		ContactSection,
		Overview,
		Reviews,
		VideoSection,
		Lazy,
	},
	mixins: [WindowSizes],
	props: {
		pageName: {
			type: String,
			required: true,
		},
	},
	data() {
		return {
			replicated: new ReplicatedSite(),
		};
	},
	computed: {
		colorsStyles() {
			return {
				'--product-primary-color': this.highlightColor,
			};
		},
		contentStyles() {
			return {
				'b, ul, h5, h6': {
					style: { color: `${this.highlightColor}` },
				},
				ul: {
					style: { paddingLeft: '1.5rem' },
				},
				'li>span': {
					class: 'font-weight-bold',
				},
				'p, li>span': {
					style: { color: '#73818f' },
				},
				b: {
					class: 'font-weight-bolder',
				},
				'h5, h6': {
					class: 'font-weight-bolder p-0 m-0',
				},
			};
		},
		highlightColor() {
			return PRODUCT_PAGES[this.pageName].color;
		},
		heroData() {
			const { background, logo, slogan } = PRODUCT_PAGES[this.pageName].hero;
			const result = {
				background: this.requireAsset(background),
				logo: this.requireAsset(logo),
			};

			if (slogan) {
				result.slogan = this.translate(slogan);
			}

			return result;
		},
		overviewData() {
			const {
				image,
				title,
				description,
				icons,
			} = PRODUCT_PAGES[this.pageName].overview;

			const iconData = icons.map((icon) => ({
				text: this.translate(icon.text),
				file: this.requireAsset(icon.file),
			}));

			const stylizedDescription = applyAttrsToHtmlString(this.translate(description), this.contentStyles);
			// eslint-disable-next-line no-nested-ternary
			const height = ['xs', 'sm'].includes(this.windowWidth) ? 850 : (['md'].includes(this.windowWidth) ? 650 : 550);

			return {
				image: this.requireAsset(image),
				title: this.translate(title),
				description: stylizedDescription,
				icons: iconData,
				height,
			};
		},
		iconsData() {
			const {
				icons,
				iconCols,
			} = PRODUCT_PAGES[this.pageName].overview;

			const result = icons.map((icon) => ({
				text: this.translate(icon.text),
				file: this.requireAsset(icon.file),
			}));

			let height = 0;
			if (this.pageName === 'uuth') {
				// eslint-disable-next-line no-nested-ternary
				height = ['xs', 'sm'].includes(this.windowWidth) ? 1250 : (['md'].includes(this.windowWidth) ? 780 : 290);
			} else {
				// eslint-disable-next-line no-nested-ternary
				height = ['xs', 'sm'].includes(this.windowWidth) ? 1350 : (['md'].includes(this.windowWidth) ? 710 : 490);
			}

			return {
				cols: iconCols,
				icons: result,
				height,
			};
		},
		videoData() {
			const {
				title,
				url,
				previewImg,
			} = PRODUCT_PAGES[this.pageName].video;

			// eslint-disable-next-line no-nested-ternary
			const height = ['xs', 'sm'].includes(this.windowWidth) ? 550 : (['md'].includes(this.windowWidth) ? 710 : 880);

			return {
				url,
				title: this.translate(title),
				previewImg: this.requireAsset(previewImg),
				height,
			};
		},
		benefitsData() {
			const {
				sections,
			} = PRODUCT_PAGES[this.pageName].benefits;

			const result = sections.map((section) => {
				const {
					image,
					title,
					content,
					cta,
					reversed,
				} = section;
				const sectionData = {
					image: this.requireAsset(image),
					title: this.translate(title),
					content: applyAttrsToHtmlString(this.translate(content), this.contentStyles),
					reversed: reversed || false,
				};

				if (cta) {
					sectionData.cta = this.transformCta(cta);
				}

				return sectionData;
			});

			// eslint-disable-next-line no-nested-ternary
			const height = ['xs', 'sm'].includes(this.windowWidth) ? 1280 : (['md'].includes(this.windowWidth) ? 1200 : 1200);

			return {
				sections: result,
				height,
			};
		},
		reviewsData() {
			const { reviews, videoUrl, textColor } = PRODUCT_PAGES[this.pageName].reviews;

			const result = reviews.map((review) => {
				const {
					image,
					author,
					content,
					stars,
				} = review;

				return {
					image: this.requireAsset(image),
					author,
					content: applyAttrsToHtmlString(`<b>"</b>${this.translate(content)}<b>"</b>`, this.contentStyles),
					stars,
				};
			});

			// eslint-disable-next-line no-nested-ternary
			const height = ['xs', 'sm'].includes(this.windowWidth) ? 1050 : (['md'].includes(this.windowWidth) ? 1200 : 1050);

			return {
				reviews: result,
				videoUrl,
				textColor,
				height,
			};
		},
		buySectionData() {
			const {
				background,
				searchName,
				title,
			} = PRODUCT_PAGES[this.pageName].buy;

			return {
				background: this.requireAsset(background),
				searchName,
				title: this.translate(title),
			};
		},
		contactData() {
			const {
				image,
				title,
				content,
				cta,
			} = PRODUCT_PAGES[this.pageName].contact;

			const ctaData = this.transformCta(cta);
			return {
				image: this.requireAsset(image),
				title: this.translate(title),
				content: this.translate(content),
				cta: ctaData,
			};
		},
		loadingReplicatedData() {
			return !!this.replicated.data.loading;
		},
		email() {
			try {
				return this.replicated.data.response.data.response.email[0].value;
			} catch (error) {
				return '';
			}
		},
	},
	created() {
		const restricted = RESTRICTED_BY_COUNTRY[this.country] ?? RESTRICTED_BY_COUNTRY.default ?? [];
		if (restricted.includes(this.pageName)) {
			this.$router.replace({ name: 'Store' });
		}

		this.replicated.clear();

		const replicatedUsername = this.$replicated.replicatedId();
		if (replicatedUsername) {
			this.replicated.getReplicatedSiteNav(replicatedUsername).catch(() => {
				this.$replicated.siteOff();
				this.$replicated.siteReplicatedSponsorOff();
				this.$replicated.siteReplicatedPageOff();
			});
		}
	},
	methods: {
		requireAsset(directory) {
			// Allow importing assets with variable directories
			/* eslint-disable global-require */
			/* eslint-disable import/no-dynamic-require */
			// require(`@/assets/images/themes/${process.env.VUE_APP_THEME}/landing_pages/${this.pageName}/${directory}`);
			return `${S3_PATH}/assets/landing-pages/${process.env.VUE_APP_THEME}/${this.pageName}/${directory}`;
		},
		transformCta(cta) {
			const result = {
				text: this.translate(cta.text),
			};

			if (cta.url) {
				result.url = cta.url;
			}

			if (cta.target) {
				result.target = cta.target;
			}
			return result;
		},
		makeHighlightStyle(propertyName) {
			if (this.highlightColor) {
				return `${propertyName}: ${this.highlightColor} !important;`;
			}
			return '';
		},
		openMailer() {
			if (this.email) {
				location.href = SPECIAL_VALUES.email(this.email); // eslint-disable-line no-restricted-globals
			}
		},
	},
};
</script>
<style scoped lang="scss">
html, body {
	scroll-behavior: smooth;
}
.landing-page::v-deep {
	.section-header {
		font-size: 3rem;
		font-family: MontserratExtraBold, Montserrat, sans-serif !important;
		font-weight: 800 !important;
	}
	.font-weight-bolder {
		font-family: MontserratExtraBold, Montserrat, sans-serif !important;
		font-weight: 800 !important;
	}
}
</style>
